import React from "react"
import tw from "twin.macro"
import { BackgroundContainerProps } from "./BackgroundContainer.d"

const BackgroundContainer: React.FC<BackgroundContainerProps> = ({
  url,
  children,
  ...remainingProps
}) => {
  return (
    <div
      css={[
        tw`bg-top bg-origin-border bg-no-repeat	bg-cover pt-20`,
        !url && tw`bg-gradient-to-b from-[#f6feff] to-white`,
        url && {
          backgroundImage: "url('/airport-interior.png')",
        },
      ]}
      {...remainingProps}
    >
      {children}
    </div>
  )
}

export default BackgroundContainer

import React from "react"
import tw from "twin.macro"
import { ModalCloseProps } from "./Modal.d"
import Close from "../Icons/Close"

const ModalClose: React.FC<ModalCloseProps> = ({
  onClose,
  closeWhite,
  ...remainingProps
}) => {
  return (
    <button
      aria-label="close"
      onClick={onClose}
      css={[tw`absolute right-6 top-4`]}
      {...remainingProps}
    >
      <Close color={`${closeWhite ? "#fff" : "#000"}`} css={[tw`w-4`]} />
    </button>
  )
}

export default ModalClose

import React from "react"
import tw from "twin.macro"
import { ModalBackgroundProps } from "./Modal.d"

const Backdrop: React.FC<ModalBackgroundProps> = ({
  onClose,
  backdropWhite,
  ...remainingProps
}) => {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      css={[
        tw`w-screen h-screen fixed z-50 left-0 top-0 bg-black bg-opacity-70 focus:(outline-none)`,
        backdropWhite && tw`bg-white bg-opacity-70`,
      ]}
      onKeyDown={e => {
        if (e.key === "Escape") onClose()
      }}
      {...remainingProps}
    ></div>
  )
}

export default Backdrop

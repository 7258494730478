import React, { useRef, useEffect, KeyboardEvent } from "react"
import { ModalProps } from "./Modal.d"
import tw from "twin.macro"
import ModalClose from "./ModalClose"
import Backdrop from "./Backdrop"

const Modal: React.FC<ModalProps> = ({
  open = false,
  children,
  backdropWhite = false,
  closeWhite = false,
  onClose,
  ...remainingProps
}) => {
  const modalRef = useRef<HTMLDialogElement>(null)
  const focusableElements =
    'a[href], button, input, textarea, select, details, [tabindex]:not([tabindex="-1"])'
  let firstFocusElement: HTMLElement
  let lastFocusElement: HTMLElement

  const handleKeyDown: any = (
    e: KeyboardEvent<HTMLElement>,
    firstFocusElement: HTMLElement,
    lastFocusElement: HTMLElement
  ) => {
    if (e.key === "Tab" && document.activeElement === lastFocusElement) {
      firstFocusElement.focus()
      e.preventDefault()
    }
    if (
      e.shiftKey &&
      e.key === "Tab" &&
      document.activeElement === firstFocusElement
    ) {
      lastFocusElement.focus()
      e.preventDefault()
    }
  }

  useEffect(() => {
    // Trap focus within modal when modal is open
    if (open && modalRef.current) {
      const modalFocusElements =
        modalRef.current.querySelectorAll(focusableElements)
      firstFocusElement = modalFocusElements[0] as HTMLElement
      lastFocusElement = modalFocusElements[
        modalFocusElements.length - 1
      ] as HTMLElement
      modalRef.current.addEventListener("keydown", e =>
        handleKeyDown(e, firstFocusElement, lastFocusElement)
      )
    }

    // // Focus within modal when opened. TabIndex must be set to -1
    // // https://dev.to/westbrookc16/managing-focus-in-react-3n13
    if (typeof window !== "undefined" && open) {
      modalRef.current.focus()
    }

    return () =>
      modalRef.current?.removeEventListener("keydown", e =>
        handleKeyDown(e, firstFocusElement, lastFocusElement)
      )
  }, [open, modalRef])

  return (
    <>
      {open && (
        <Backdrop onClose={onClose} backdropWhite={backdropWhite}>
          <dialog
            ref={modalRef}
            tabIndex={-1}
            aria-label={"dialog box"}
            aria-labelledby="title"
            aria-describedby="description"
            css={[
              tw`block rounded absolute z-50 bg-white top-1/2 transform -translate-y-1/2 max-h-screen max-w-screen overflow-y-scroll focus:outline-none`,
            ]}
            {...remainingProps}
          >
            {children}
            <ModalClose onClose={onClose} closeWhite={closeWhite} />
          </dialog>
        </Backdrop>
      )}
    </>
  )
}

export default Modal

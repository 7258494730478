import React, { useState, useEffect, useContext, useRef } from "react"
import tw from "twin.macro"
import { PageHeadingProps } from "./PageHeading.d"
import { H1 } from "../Typography"
import TranslateClient from "../../clients/TranslateClient"
import { LanguageContext } from "../../context/Language"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"

const PageHeading: React.FC<PageHeadingProps> = ({
  heading,
  content,
  animate,
}) => {
  const headingContent = useRef(null)
  const { language } = useContext(LanguageContext)
  const [text, setText] = useState({ heading, content })

  useEffect(() => {
    if (!animate) return
    headingContent?.current?.classList.add("fade-in")
  }, [])

  useEffect(() => {
    if (language === "en") {
      setText({ heading, content })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([heading, content])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <section
      ref={headingContent}
      css={[
        tw`container mx-auto text-center px-6 lg:w-4/5 xl:w-3/5 2xl:max-w-[1024px]`,
      ]}
    >
      <H1>{text?.heading}</H1>
      <p
        css={[tw`text-lg`]}
        dangerouslySetInnerHTML={{ __html: text?.content }}
      />
    </section>
  )
}

export default PageHeading
